import { template as template_088e7ab155f4435b9a3571258fa64b70 } from "@ember/template-compiler";
const FKText = template_088e7ab155f4435b9a3571258fa64b70(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
