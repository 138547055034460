import { template as template_af608ec907624159a67761fb3982caad } from "@ember/template-compiler";
const FKLabel = template_af608ec907624159a67761fb3982caad(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
