import { template as template_345440c7c4b543029e3c04bb5e83e19d } from "@ember/template-compiler";
const WelcomeHeader = template_345440c7c4b543029e3c04bb5e83e19d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
