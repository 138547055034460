import { template as template_3c1d76a10efe4e8fa15c8e114f8eea06 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3c1d76a10efe4e8fa15c8e114f8eea06(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
